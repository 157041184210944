import { captureException, type ErrorEvent, type Exception, init, setUser } from '@sentry/vue'

import { InMemoryCache } from './cache/InMemoryCache.ts'

interface SentryUser {
  id: number
  email: string
  first_name: string
  last_name: string
}

export const sentry = {
  captureException,
  cache: InMemoryCache.tags('sentry'),
  blacklist: [
    'cancel',
    'canceled',
    'CanceledError',
    'close',
    'Load failed',
    'Network Error',
    'Request failed with status code 500',
    'Unsaved changes',
    'Validation failed',
    /AbortError/,
    /Failed to fetch/,
    /Fetch is aborted/,
    /No error message/,
    /Request aborted due to a new request with the same fingerprint/,
    /Request failed with status code 4[0-9]{2}/,
    /Unable to preload CSS for/,
    /Unauthorized route/,
    /user aborted a request/,
  ],
  user: null as SentryUser | null,
  dsn: '',
  env: '',

  setUser(user: SentryUser) {
    this.user = user
  },

  setDsn(dsn: string) {
    this.dsn = dsn
  },

  setEnv(env: string) {
    this.env = env
  },

  run() {
    if (!this.dsn) {
      throw new Error('DSN is required')
    }
    const vm = this

    init({
      app,
      dsn: vm.dsn,
      environment: vm.env,
      ignoreErrors: [/ResizeObserver loop.*/i, /this.isExpired is not a function/i],
      async beforeSend(event) {
        return await vm.beforeSend(event)
      },
    })

    if (this.user) {
      setUser({
        id: `${this.user.id}`,
        email: this.user.email,
        'First name': this.user.first_name,
        'Last name': this.user.last_name,
      })
    }
  },

  async beforeSend(event: ErrorEvent) {
    event = this.shouldReport(event)
    event = await this.rateLimit(event)

    if (event.exception?.values?.length === 0) {
      return null
    }

    return event
  },

  shouldReport(event: ErrorEvent) {
    if (event.exception?.values) {
      event.exception.values = event.exception.values.filter((exception) => {
        if (this.blacklist.some((message) => (message instanceof RegExp ? message.test(exception.value as string) : message === exception.value))) {
          //  eslint-disable-next-line no-console
          console.log('Sentry blacklisted:', exception)

          return false
        }

        return true
      })
    }

    return event
  },

  async rateLimit(event: ErrorEvent) {
    if (event.exception?.values) {
      const exceptionValues: Exception[] = []

      for (const exception of event.exception.values) {
        const key = `${exception.type}:${exception.value}`
        if (await this.cache.has(key)) {
          //  eslint-disable-next-line no-console
          console.log('Sentry rate limited:', exception)
        } else {
          this.cache.put(key, '', dayjs().add(1, 'minute'))
          exceptionValues.push(exception)
        }
      }

      event.exception.values = exceptionValues
    }

    return event
  },
}
